import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { atHomeStore, alunoStore } from '@/store';
import DataTable from './DataTable.vue';
let Notas = class Notas extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            { text: 'Atividade', value: 'atividade_descricao' },
            { text: 'Dia Aula', value: 'atividade_data_executada' },
            { text: 'Presença', value: 'atividade_presenca' },
        ];
        this.id = null;
        this.loading = null;
        this.items = [];
        this.data = {};
        this.loadingBoletim = false;
    }
    getPresenca(item) {
        if (item.reposicao_concluida) {
            return { text: 'Reposição concluída', color: 'inherit' };
        }
        if (item.reposicao_nao_repor) {
            return { text: 'Não irá repor', color: 'var(--v-error)' };
        }
        if (item.reposicao_agendada) {
            return { text: 'Reposição agendada', color: 'inherit' };
        }
        if (item.atividade_presenca == null) {
            return { text: 'N/D', color: 'inherit' };
        }
        if (item.atividade_presenca) {
            return { text: 'Presente', color: 'inherit' };
        }
        return { text: 'Ausente', color: 'var(--v-error)' };
    }
    async imprimirBoletim() {
        if (this.loadingBoletim) {
            return;
        }
        this.loadingBoletim = true;
        const res = await alunoStore.getBoletim(this.id);
        if (res.data) {
            if (res.headers['content-type'].includes('html')) {
                const html = res.data;
                const w = window.open();
                w.document.write(html);
                setTimeout(() => {
                    w.print();
                }, 1000);
            }
            else {
                const uri = 'data:image/png;base64,' + res.data;
                const a = document.createElement('a');
                a.href = uri;
                a.download = 'Boletim.png';
                a.click();
            }
        }
        this.loadingBoletim = false;
    }
    async mounted() {
        this.loading = true;
        this.id = Number(this.$route.params.id);
        this.items = await atHomeStore.getAtHomeNotas(this.id);
        this.data = await atHomeStore.getAtHomeTurma(this.id);
        if (this.data.nota_homework) {
            this.headers.push({ text: 'Homework', value: 'atividade_nota' });
        }
        if (this.data.nota_preparacao) {
            this.headers.push({ text: 'Preparação', value: 'atividade_nota_preparacao' });
        }
        if (this.data.nota_audicao) {
            this.headers.push({ text: 'Audição', value: 'atividade_nota_audicao' });
        }
        if (this.data.nota_escrita) {
            this.headers.push({ text: 'Escrita', value: 'atividade_nota_escrita' });
        }
        if (this.data.nota_fala) {
            this.headers.push({ text: 'Fala', value: 'atividade_nota_fala' });
        }
        if (this.data.nota_leitura) {
            this.headers.push({ text: 'Leitura', value: 'atividade_nota_leitura' });
        }
        if (this.data.nota_participacao) {
            this.headers.push({ text: 'Participação', value: 'atividade_nota_participacao' });
        }
        this.loading = false;
    }
};
Notas = __decorate([
    Component({
        components: {
            DataTable,
        },
    })
], Notas);
export default Notas;
